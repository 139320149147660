<template>
<div class="home">
	<header class="hero">
		<NavbarMain></NavbarMain>
		<div class="d-flex justify-content-center align-items-center flex-column text-center">
			<div class="interior">
				<div class="display-1 mb-5">
					Your front-end superpower.
				</div>
				<div class="description">
					<div class="lead mb-5">
						Amino is a compact and powerful browser extension that allows you to customize any website with CSS. Use the editor to create your own website themes and skins.
					</div>
					<div class="d-flex justify-content-center">
						<div class="mr-3">
							<b-button variant="primary" rel="noopener" :href="getExtensionLink('chrome')" target="_blank">
								Add To Chrome
								<span class="material-icons-outlined">chevron_right</span>
							</b-button>
						</div>
						<div>
							<b-button variant="outline-dark" rel="noopener" href="#scroll-to">
								Learn More
								<span class="material-icons-outlined">chevron_right</span>
							</b-button>
						</div>
					</div>
				</div>
			</div>
			<b-container>
				<img class="img-fluid" src="@/assets/images/home/montage.png">
			</b-container>
		</div>
	</header>
	<section class="highlights" id="scroll-to">
		<header class="d-flex flex-column justify-content-center align-items-center text-center">
			<div class="display-2">
				See what you're able to do with Amino.
			</div>
			<div class="text-muted lead mt-2">
				The many powerful features of Amino.
			</div>
		</header>
		<b-container>
			<vue-aos animation-class="slide-up">
				<div class="section d-flex align-items-center justify-content-between">
					<div class="description">
						<div class="h1 mb-4">
							Infinite possibilities.
							<!-- Make visual changes to any website with CSS. -->
						</div>
						<div class="text-muted mb-4">
							Unlike modifying CSS in DevTools, the stylesheets you write with Amino are applied every time the page is viewed, making them effectively permanent while the stylesheet is enabled.
						</div>
						<ul class="text-left feature-highlights list-unstyled mb-0">
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Compose CSS in a best-in-class text editor
							</li>
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Enjoy familiar IDE tools like autocomplete, auto-indent
							</li>
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Write in CSS or SCSS format. Both are supported
							</li>
						</ul>
					</div>
					<div class="image rounded">
						<div class="wrapper">
							<img src="@/assets/images/home/screenshot-1.png" class="img-fluid">
						</div>
					</div>
				</div>
			</vue-aos>
			<vue-aos animation-class="slide-up">
				<div class="section swapped d-flex align-items-center justify-content-between">
					<div class="description">
						<div class="h1 mb-4">
							Style sites selectively.
						</div>
						<div class="text-muted mb-4">
							Style only the pages you want to style. Apply persistent stylesheets to individual pages or entire domains.
						</div>
						<ul class="text-left feature-highlights list-unstyled mb-0">
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Style by page or by domain
							</li>
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Toggle individual stylesheets on and off as needed
							</li>
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Disable all stylesheets with the click of a button
							</li>
						</ul>
					</div>
					<div class="image rounded">
						<div class="wrapper">
							<img src="@/assets/images/home/screenshot-2.png" class="img-fluid">
						</div>
					</div>
				</div>
			</vue-aos>
			<vue-aos animation-class="slide-up">
				<div class="section d-flex align-items-center justify-content-between">
					<div class="description">
						<div class="h1 mb-4">
							Inspect websites and easily find the right selectors and colors.
						</div>
						<div class="text-muted mb-4">
							Want to modify a particular element and need the selector name? Our inspector tool will easily help you find the right selectors and colors.
						</div>
						<ul class="text-left feature-highlights list-unstyled mb-0">
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Hover over any element and see the selector name
							</li>
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Copy it to the editor and begin styling
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Eyedropper mode to select colors on the page
							</li>
						</ul>
					</div>
					<div class="image rounded">
						<div class="wrapper">
							<img src="@/assets/images/home/screenshot-3.png" class="img-fluid">
						</div>
					</div>
				</div>
			</vue-aos>
			<vue-aos animation-class="slide-up">
				<div class="section swapped d-flex align-items-center justify-content-between">
					<div class="description">
						<div class="h1 mb-4">
							Robust interface to manage your library of styles and collections.
						</div>
						<div class="text-muted mb-4">
							With Amino's progressive web app you can enjoy free cloud storage of your stylesheets and sync them across devices wherever Amino is installed.
						</div>
						<ul class="text-left feature-highlights list-unstyled mb-0">
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Easily manage large amounts of stylesheets
							</li>
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Stay organized by sorting your items into collections
							</li>
							<li>
								<span class="material-icons-outlined mr-2">done</span>
								Available as a Progressive Web App (PWA)
							</li>
						</ul>
					</div>
					<div class="image rounded">
						<div class="wrapper">
							<img src="@/assets/images/home/screenshot-4.png" class="img-fluid">
						</div>
					</div>
				</div>
			</vue-aos>
		</b-container>
	</section>
	<FooterMain></FooterMain>
</div>
</template>

<script>
// Components
const NavbarMain = () => import('@/components/NavbarMain');
const FooterMain = () => import('@/components/FooterMain');
const VueAos = () => import('vue-aos');

// Images for lazy loading
// const screenshotEditor = require('../assets/images/home/editor-montage.svg');
// const screenshotSplitEditor = require('../assets/images/home/screenshot-split-editor.webp');
// const screenshotInspect = require('../assets/images/home/inspector-montage.svg');

export default {
	name: 'Home',
	data() {
		return {
			// screenshotEditor,
			// screenshotSplitEditor,
			// screenshotInspect
		}
	},
	components: {
		VueAos,
		NavbarMain,
		FooterMain
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/css/partials/mixins";
.hero {
	background-color: var(--color-700);
	.display-1 {
		max-width: 800px;
		font-size: clamp(3rem,7vw,5.5rem);
		line-height: 0.9;
	}
	.interior {
		padding: 7rem 2rem;
	}
	.description {
		max-width: 700px;
		margin: auto;
	}
}

.highlights {
	padding: 7rem 0;
	@media (max-width: 992px) {
		padding: 3.5rem 0;
	}
	header {
		margin-bottom: 2rem;
		padding: 0 2rem;
		.display-2 {
			max-width: 420px;
			font-size: 2.5rem;
			line-height: 1.3;
			margin-bottom: 1rem;
			@media (max-width: 992px) {
				font-size: 2rem;
				margin-bottom: 0.5rem;
			}
		}
	}

	.blurb {
		max-width: 375px;
		@media (max-width: 992px) {
			max-width: 100%;
		}
	}

	.section {
		padding: 5rem 0;
		@media (max-width: 992px) {
			padding: 2rem 0;
			flex-direction: column;
			.description {
				order: 2 !important;
				margin: 0 !important;
			}
			.image {
				order: 1 !important;
				margin-bottom: 2rem;
			}
		}
		&.swapped {
			.description {
				order: 2;
				margin-left: 1.5rem;
			}
			.image {
				order: 1;
			}
		}
		.description {
			max-width: 420px;
			margin-right: 1.5rem;
			.h1 {
				font-size: 2.125rem;
				line-height: 1.2;
			}
		}
		.image {
			background-color: var(--color-500);
			max-width: 550px;
			overflow: hidden;
			img {
				transition: 0.3s ease;
			}
			&:hover {
				img {
					transform: scale(1.05);
				}
			}

		}
	}
	.item {
		padding: 10vh 0;
		.wrapper {
			flex-grow: 1;
			min-width: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			align-items: flex-start;
		}
		.content {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 1000px;
			height: 680px;
			border-radius: 24px;
			overflow: hidden;
			position: relative;
			max-width: 1100px;
		}
	}
}

.feature-highlights {
	// font-weight: var(--font-weight-bold);
	// font-size: 0.875rem;
	li {
		.material-icons-outlined {
			font-size: 1.25rem;
			color: var(--color-primary);
		}
		&:not(:last-of-type) {
			margin-bottom: 0.65rem;
		}
	}
}

.animate {
	transition-duration: 0.15s;
	transition-timing-function: ease-in;
}

.slide-up {
	opacity: 0;
	visibility: hidden;
	transform: translateY(100px);
}

.slide-up.animate-active {
	opacity: 1;
	transform: translateY(0);
	visibility: visible;
}
</style>
